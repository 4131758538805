import React from "react";
import Layout from '../../UI/Layout';
import ThumbsOptions from "../../components/ThumbsOptions";
import apps from '../../../queries/data/mobile-index';

const Index = () => {

  const dataHeader = apps.data.wp.home.dataHeader;

    return (
      <Layout showButtons dataHeader={dataHeader}>
          <ThumbsOptions data={apps} />
      </Layout>
    )
}

export default Index;
import thumb1 from '../../src/images/data/Sky_Mobile_Roll_Apps_Retail_App_Assets_IT_Desktop.png';
import thumb2 from '../../src/images/data/Sky_Mobile_Watch_Apps_Retail_App_Assets_IT_Desktop.png';
import thumb3 from '../../src/images/data/Sky_Mobile_Text_to_switch_Apps_Retail_App_Assets_IT_Desktop.png';
import thumb4 from '../../src/images/data/Sky_Mobile_Swap_Apps_Retail_App_Assets_IT_Desktop.png';
import thumb5 from '../../src/images/data/Sky_Mobile_Mix_Apps_Retail_App_Assets_IT_Desktop.png';

import img1 from '../../src/images/data/Mobile_Retail_App_Assets_IT_pg5_Desktop.png';

import logo from '../../src/assets/logo.jpg';

const apps = {
    data : {
        wp: {
            home: {
                dataHeader : {
                    home:true, 
                    logo: logo,
                    back:true
                },
                title : 'Select a category',
                options : [
                    {
                        id: 'roll',
                        title : 'Roll',
                        thumb : thumb1,
                        img : img1,
                        link: '/mobile/roll', 
                    },
                    {
                        id: 'watch',
                        title : 'Watch',
                        thumb : thumb2,
                        img : img1,
                        link: '/mobile/watch',
                    },
                    {
                        id: 'text-to-switch',
                        title : 'Text-to-Switch',
                        thumb : thumb3,
                        img : img1,
                        link: '/mobile/text-to-switch'
                    },
                    {
                        id: 'swap',
                        title : 'Swap',
                        thumb : thumb4,
                        img : img1,
                        link: '/mobile/swap'
                    },
                    {
                        id: 'mix',
                        title : 'Mix',
                        thumb : thumb5,
                        img : img1,
                        link: '/mobile/mix'
                    },
                ]
            }
        },

    }
}

export default apps;